import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';

import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" href="/assets/favicon.ico" />
        <meta name="theme-color" content="#ffffff" />
        <meta
        name="description"
        content="it outsourcing, bodyleasing"
        />
        <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
        property="og:image"
        content="https://scontent.fktw1-1.fna.fbcdn.net/v/t1.6435-9/125362325_100479578557917_484782224246202310_n.png?_nc_cat=101&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=laO5C0A1uQEAX_bp0e0&tn=fDEqGRGK48A6k7in&_nc_ht=scontent.fktw1-1.fna&oh=0a900b7f381dab6bff2c07603d7c3072&oe=60C301A1"
        />
        <meta
        property="og:title"
        content="codeqwerty.com"
        />
        <meta
        property="og:description"
        content="codeqwerty.com | it outsourcing, bodyleasing"
        />
        <meta property="og:url" content="https://codeqwerty.com/" />
        <link
        href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
        rel="stylesheet"
        />
        <script
        src="https://kit.fontawesome.com/4c273e6d43.js"
        crossOrigin="anonymous"
        ></script>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
