/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import TopLayout from './TopLayout';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'

export const wrapRootElement = ({element}) => {
    return <GoogleReCaptchaProvider reCaptchaKey="6LcLMtIaAAAAALPfyrX9T0QbnAXnZDfOwqT0CZns">
        <TopLayout>{element}</TopLayout>
    </GoogleReCaptchaProvider>;
};
